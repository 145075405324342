import React from "react";
import style from "./Trusted.module.css"
import { Link, useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";
const Trusted = (props) =>{
      const location = useLocation();
    
    const content = [
        {
            image:"/image/t11.webp",
            title:"Zoologist Approved",
            description:"Every box is designed with a team of zoologists. So every box is like a tour of the zoo by a professional zoologist.",
            contact:"",
        },
        {
            image:"/image/t222.webp",
            title:"30 Day Money Back Guarantee",
            description:"If you're not happy, we'll send you a 100% refund within 30 days. No questions asked.",
            contact:"",
        },
       
        {
            image:"/image/t33.webp",
            title:"Dedicated Team of Educators",
            description:"Our team of certified teachers make sure kids will love everything. And that it's age appropriate.",
            contact:"",
        },
        {
            image:"/image/t44.webp",
            title:"Quality PAW-surred",
            description:"Any issues? Contact us and we'll make it right or send a replacement. ",
            contact:"Contact Us Now",
        },
       
    ]
    return(


      <div className={`pt50 pb50 ${style.trusted} `}>
          <div className="container">
            <h1>The My ZOO Box Guarantee</h1>
            <p className="pb15">Trusted by grown-ups. Loved by kids.<br/>Hours of learning and bonding delivered!</p>
          {  location.pathname === "/zoologist-club" ?
 <div className={`container ${style.badgeHome}`}>
        
        <div>
          <img src="/image/5y.png"/>
        </div>
        <div>
          <h2>5 Years of Fresh Tracks</h2>
          <p>We promise your child won’t meet the same animal twice for 5 full years. Every box is a new adventure, packed with unique creatures to keep curiosity wild and learning endless!</p>
        </div>
      
          </div>
             : "" }
            <div className={style.trustedBox}>
                {content.map(((item,i) =>{
                    return(
                        <div className={`${style.trustedBoxes}`} style={{background:props.trustedBck}} key={i}>
                        <img src={item.image}/>
                         <div>
                           <h3>{item.title}</h3>
                           <p>{item.description}</p>
                           {item.contact !== "" ? <div className={style.orange}><Link to="/contact-us" onClick={() => animateScroll.scrollToTop()}>{item.contact}</Link> <img src="/image/icon/orange.svg"/>  </div>   : ""}
                        
                       
                         </div>
                    </div>
                    )
                }))}
              
            </div>
        </div>
      </div>
    )
}
export default Trusted;