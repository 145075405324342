import React, { useState } from "react";
import style from "./homepage.module.css"
import Button from "../../component/button/button";
import Clubs from "../../component/clubs/Clubs";
import Curiosity from "../../component/curiosity/Curiosity";
import { Link } from "react-router-dom";
import KidLove from "../../component/kidLove/kidLove";
import Testimonials from "../../component/testimonials/Testimonials";
import Trusted from "../../component/trusted/Trusted";
import FAQ from "../../component/faq/Faq";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import Newsletter from "../../component/newsletter/Newsletter";
import KiwiCoAd from "../../component/kiwi/kiwi";
import Popup from "../../component/popup/Popup.js";
const Home = (props) =>{
 
    const faqData = [
      {
        question: 'What is the recommended age range for My ZOO Box?',
        answer: "We have 2 clubs to meet the needs of a wide range of developmental levels. Cub Club for ages 3-5 focuses on foundational preschool skills in a fun way with animals. Zoologist Club for ages 5-12 focuses on learning about one animal per month and brings STEM, math, life skills, and more to your little zoologist.          ",
      },
      {
        question: 'Can I give My ZOO Box as a gift?',
        answer: 'Of course! Our subscriptions make wonderful gifts! Simply visit our website and make your gift selection (1, 6 or 12-month subscription). Then, provide the details such as the child’s name and shipping address. You can also send other one-time ZOO Shop items as gifts too!',
      },
      {
          question: 'When will my box ship?',
          answer: "Monthly EDventures ship the first 2 weeks of each month. Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S. Orders placed by the last day of the month will receive the next month's box. Orders placed in February are for March's Box, etc.",
        },
        {
          question: 'How much does shipping cost?',
          answer: (
            <p>All subscriptions include free shipping to anywhere in the United States, including Hawaii, Alaska, and Military (APO, FPO, DPO) addresses.
            <br/>
           
<br/>
Shipping for The ZOO Store is $7.95 for USA customers and $12.95 for Canadian customers.
<br/>
         
            </p>
          )
        },
        {
          question: 'How much does My ZOO Box cost?',
          answer: 'Monthly subscriptions are $37.95 per month for most clubs. The Zoologist Club Mini Zoo is $23.95. You can receive a discount on the per-EDventure price by signing up for a 6, or 12-month subscriptions.      ',
        },
      ];
    const kids = [
      {
        image:"/image/lov1.png"
      },
      {
        image:"/image/lov2.png"
      },
      {
        image:"/image/lov3.png"
      },
      {
        image:"/image/lov4.png"
      },
      {
        image:"/image/lov5.png"
      },
      {
        image:"/image/lov6.png"
      },
      {
        image:"/image/lov7.png"
      },
      {
        image:"/image/lov8.png"
      },
      {
        image:"/image/lov9.png"
      },
      {
        image:"/image/lov10.png"
      },
      {
        image:"/image/lov11.png"
      },
      {
        image:"/image/lov12.png"
      },
    ]

    // const openFunction  = () =>{
    //   setOpen(true)

    // }
    const clubSelect = {
      title:"Turn Animal Passion into Discovery!",
      icon:"/image/icon/cubb.svg",
      description:"Learn ABCs, problem-solving, and wildlife wonders-all through the world of animals.",

      name:"Cub Club",
      link:"/cub-plan",
      halfbg:"/image/recb.png",
      color:"#e3f9ff",
      video:"/image/cub.mp4",
      leaf:"/image/a.png",
      leafTwo:"/image/bb.png",
      button:"#f03b28",
      new:"newClass"
    }
    const zooSelect = {
      title:"Where Play Meets the Animal Kingdom!",
      description:"Trek into wild habitats through STEM learning, and nurture empathy through hand-on fun.",

      icon:"/image/icon/zooo.svg",
      name:"Zoologist Club",
      link:"/zoologist-plan",
      halfbg:"/image/rec.png",
      color:"#fcf6e1",
      video:"/image/zoo.mp4",
      leafThree:"./image/bark.png",
            button:"#00ab5f",
      new:"newClass"
    }
    return(
        <>
          <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
      {/* <Popup/> */}
          <div className={style.homebck}>
          <div className="container">
            <div className={style.banner}>
             <div className={style.bannerContent}>
              <h1>
              Discover the <br/>World of Animals
              </h1>
              <p>
                 Where Learning meets<br/> Conservation.
              </p>
              <div className={style.buttonDis}>
              <Button dynamicClass="fillColor" text="Get Started" link="/select-plans"/>
              <Button dynamicClass="borderColor" text="Gift it"  link="/choose-gift-club"/>
              </div>
        
            </div>
            <div className={style.homeDes}>
                <img src="/image/banner1.webp"/>
                <img src="/image/banner2.webp"/>
           
            </div>
            <div className={style.homeMob}>
            <Swiper
                    navigation={true}
                    pagination={{   
                      clickable: true,
                    }}
        // navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide> <img src="/image/banner1.webp"/></SwiperSlide>
        <SwiperSlide> <img src="/image/banner2.webp"/></SwiperSlide>
      
      
      </Swiper>
            </div>
         </div>
     
        </div>
          </div>

          {/* <div className={`allShopV ${style.homeSlider}`}>
            <div className="container">
            <Swiper
  
  navigation={true}
  pagination={{ clickable: true }}  modules={[Pagination,Navigation]}
  className="mySwiper"
  breakpoints={{

    768: {
      slidesPerView: 1,
    },
  
    0: {
      slidesPerView: 1,
    },
  }}
>
  <SwiperSlide className={style.swiperDes}>
    <KiwiCoAd data={clubSelect}/>
  </SwiperSlide>
  
  <SwiperSlide  className={style.swiperDes}>
  <KiwiCoAd data={zooSelect}/>
  </SwiperSlide>
  

</Swiper>
            </div>
          </div> */}
         
          <Trusted/>
        <Clubs />
        <Curiosity/>
        <div className={style.learn}>
            <div className="thirdContainer pt50">
               <div className={style.learnHeading}>
               <h1>Start the BEST learning EDventure today!</h1>
                <p>Receive <span>FREE SHIPPING</span> in the United States for all subscriptions!<br/><br/>
                   Subscriptions ship within the first two weeks of each month. Orders placed on or after the 1st will be processed in the following month’s shipment.
                </p>
          
                <Button dynamicClass="fillCubColor" text="Get Started" link="/select-plans"                               />
                </div>
                <div className={`pt50 pb50 ${style.learnDis}`}>
                    <div className={style.learnBoxes}>
                    <div className={style.learnBox}>
                        <span>Delivering Animal Fun Since 2020</span>
                        <h3>Ready for an explosion<br/> of excitement?</h3>
                        <p>Do your kids get THIS excited to learn?</p>
                        <p>Join now and see just how captivating screen-free learning can be!</p>
                        <Button dynamicClass="fillColor" text="Get Started" link="/select-plans"/>
                    </div>
                    <iframe   width="560" height="315" src="https://www.youtube.com/embed/eVSQQpWuyf8?si=kO8vyMxzZnSDDeuJ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen autoPlay></iframe>
                  {/* {!open &&  <div className={style.learnBck}>
                
                <div onClick={openFunction}>
                  <img src="/image/icon/play.svg"/>
                <p>Play Video</p>
                </div>
           
                 <img src="/image/tik.webp" />
              </div>} */}
                   
                    </div>
                    <div className={style.learnBoxes}>
                    {/* <div className={style.learnBckTwo}>
                       <img src="/image/trees.webp"/>
                    </div>
                    <div className={style.learnBoxTwo}>
                        <span>One Tree Planted Partnership</span>
                        <h3>Since May 1, We have planted 8,000 + Trees</h3>
                        <p>At My ZOO Box we know trees benefit every single living creature on this planet. As such, we decided the way we could make the most impact for our animal friends is to work on reforestation efforts with One Tree Planted! </p>
                   
                        <Button dynamicClass="fillColor" text="Get Started"/>
                    </div> */}
                    </div>
                 
                </div>
            </div>
         </div>
           <KidLove kid={kids} title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/>

            <Testimonials/>
      
            <div className="pt50 pb50 subContainer">
              <FAQ data={faqData} section={props.section}/>
            </div>
            <Newsletter/>
            </>
    )
}
export default Home