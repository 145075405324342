import React, { useEffect } from "react";
import Hub from "../../component/hub/Hub";
import Trusted from "../../component/trusted/Trusted";
import Fan from "../../component/fan/Fan";
import { Link } from "react-router-dom";


const NextEdventure = () =>{
    const hub = {
        header:"Welcome to the Animal of the Month Hub 👋        ",
        link:"/your-first-edventure",
        nav: (
            <span>
                NOTE: If you are expecting your first box, please  
                 <Link to="/your-first-edventure">Click Here</Link> 
                to continue learning about your first EDventure!
            </span>
        ),
      
        box:[
            {
                icon:"/image/icon/cubb.svg",
                question:"What Animal EDventure Ships with Your Next Cub Club Box?",
                name:"The Butterfly",
                description:"Get the corresponding unit study plus 4 short videos to extend the learning for only $7",
                image:"/image/fl.png",

                // linkText:"Get The Panda Unit Study",
                link:""
                
            },
            {
                icon:"/image/icon/zooo.svg",
                question:"What Animal EDventure Ships with Your Next Zoologist Club Box?",
                name:"The Peacock",

                description:"Get the corresponding unit study and extend the learning for only $5",
                image:"/image/pc.png",

                reverse:"reverse",
                // linkText:"Get the Hummingbird Unit Study",
                link:""
                
            },
        ]
    }

    function updateHubData() {
        const currentDate = new Date().toLocaleDateString("en-US", { timeZone: "America/New_York" });
    
        if (currentDate === "2/28/2025") {
         return;
        }else{
            hub.box = hub.box.map(item => {
                if (item.name === "The Butterfly") {
                    return { ...item, name: "The Tiger", image: "/image/ti.png" };
                } else if (item.name === "The Peacock") {
                    return { ...item, name: "The Dolphin", image: "/image/do.png" };
                }
                return item;
            });
        }
    }
    
    // Call the function to check and update
    updateHubData();
    
    
    const zooStore = [
        // {
        //          image:"/image/fan.png",
        //          price:"$39.95",
        //          name:"Deer EDventure",
        //         cart:"https://checkout.myzoobox.com/checkout/add/119420",
        // },
        {
            image:"/image/fan2.png",
            // stock:"Low Stock", 
    price: "$29.96",
            name:"Hammerhead Shark EDventure",
            cart:"https://checkout.myzoobox.com/checkout/add/257095",
   },
//    {
//     image:"/image/fan3.png",
//     // stock:"Low Stock", 
//     price: "$29.96",
//     name:"Sea Lion EDventure    ",
//     cart:"https://checkout.myzoobox.com/checkout/add/247026",
// },
{
    image:"/image/moose.jpg",

    // stock:"Low Stock Less Than 10", 
    price: "$23.97",
    name:"Moose EDventure",
    cart:"https://checkout.myzoobox.com/checkout/buy/310564",
},
    ]
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          window.subblyConfig = {
              apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
              settings: {
                  interceptProductLinks: true,
                  cartCounterEl: '.subbly-cart-product-count',
                  cartToggleEl: '.subbly-cart',
                  cartButton: true,
              },
          };
        `;
        document.body.appendChild(script);
    
        const subblyScript = document.createElement('script');
        subblyScript.type = 'module';
        subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
        document.body.appendChild(subblyScript);
    
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(subblyScript);
        };
      }, []);
    
    return(
     <>
       
       <Hub hub={hub}/>
            <div className="container">
            <Fan boxes={zooStore}/>
            <Trusted />
        </div></>
    )
}

export default NextEdventure;